import React, { useEffect, useState } from "react";
import "./style.scss";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectSUser } from "../../features/user/userSlice";
import { selectDay } from "../../features/day/daySlice";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../firebase/firebase";
import { getloginUserThunk } from "../../features/user/userAPI";
import { getTodayThunk } from "../../features/day/dayAPI";
import { Diet, selectDiet } from "../../features/diet/dietSlice";
import { deleteDietByIdThunk, getAllDietThunk } from "../../features/diet/dietAPI";
import { Food } from "../../features/food/foodSlice";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";

export const Diets = React.memo(() => {
    const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user_log } = useAppSelector(selectSUser);
  const { today } = useAppSelector(selectDay);
  const {diets} = useAppSelector(selectDiet)
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user && user.email) {
        dispatch(getloginUserThunk(user.email))
          .unwrap()
          .then((res) => {
            if (res && res.id) {
              dispatch(getTodayThunk(res?.id)).then(res=>{
                dispatch(getAllDietThunk())
              });
            }
          });
      } else {
        navigate("/");
      }
    });
  }, []);
  const [selectedOption, setSelectedOption] = useState(''); 
  const options = ['Option 1', 'Option 2', 'Option 3'];
  const add = ()=>{
  }
  return (
    <div className="diets" style={{padding:"10px"}}>
      <Button
            variant="primary"
            onClick={() =>add()}
            style={{ width: "90px" }}
          >Add Diet</Button>
      <Button
            variant="primary"
            onClick={() => navigate("/add-food?add=diet")}
            style={{ width: "90px" }}
          >Add Diet</Button>
      <div>
        {diets?.map((e:Diet)=>{
          return <div key={e.id}>
            <h4>{e.name}</h4>
            <Button
            variant="primary"
            onClick={() => dispatch(deleteDietByIdThunk(e.id))}
            style={{ width: "90px" }}
          >delete</Button>
            <div className="foods">
              {e.foods?.map((f:any)=>{
                return <div style={{display:"flex",gap:"10px"}}>
                  <p><img src={f.photo_url} width={20} />{f.food_name} ({f.value}{f.category == "Juice"?"ml":"g"}) - {f.calorie_count} calorie</p>
                </div>
              })}
              </div>
          </div>
        })}
      </div>
    </div>
  );
});
